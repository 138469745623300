import React from 'react';
import { Box } from '@material-ui/core';
import { PAIDY_HOST, SITE_METADATA } from 'src/constants';

// Unchanged components
import { StickyNav } from 'src/components';
import Layout from 'src/components/Layout/Layout';
import LinearCTAButton from 'src/components/LinearCTAButton';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/GeneralCashback202402/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202407/JulyPaymentSteps';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202407/JulyAppDownload';

// Changed components
import PaidyBenefits from 'src/components/Merchant/Campaign/GeneralCashback202503/PaidyBenefits';
import SeptPayLater from 'src/components/Merchant/Campaign/GeneralCashback202503/SeptPayLater';
import Condition from 'src/components/Merchant/Campaign/GeneralCashback202503/Condition';
import SectionNotes from 'src/components/Merchant/Campaign/GeneralCashback202503/SectionNotes';
import CashbackBanner from 'src/components/Merchant/Campaign/GeneralCashback202503/CashbackBanner';
import CashbackTopBanner from 'src/components/Merchant/Campaign/GeneralCashback202503/CashbackTopBanner';

import styles from './general_cashback_202503.module.scss';

const SEOProps = {
  ogImage: `${PAIDY_HOST}/ogp_general_cashback_202503.png`,
  twitterImage: `${PAIDY_HOST}/ogp_general_cashback_202503.png`,
  title: SITE_METADATA.general_cashback_202503.title,
  description: SITE_METADATA.general_cashback_202503.description,
};
const NAVS = [
  'キャンペーン内容',
  'ペイディについて',
  'キャンペーン',
  'ご注意事項',
];

export default function GeneralCashback202503() {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter backgroundColor="white">
      <CashbackTopBanner />
      <Box className={styles.ctaWrapper}>
        <LinearCTAButton
          text="使えるお店を探す"
          to={`${PAIDY_HOST}/shop/`}
          isExternal
        />
      </Box>
      <StickyNav navs={NAVS} />
      <Condition id={NAVS[0]} />
      <SeptPayLater id={NAVS[1]} />
      <JulyPaymentSteps />
      <CashbackBanner id={NAVS[2]} />
      <PaidyBenefits title="ペイディが選ばれる理由" />
      <SectionNotes id={NAVS[3]} />
      <JulyAppDownload
        text={
          <>
            1,600万 <br />
            ダウンロード突破！
          </>
        }
      />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
}
