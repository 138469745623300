import React from 'react';
import { Hidden } from '@material-ui/core';
import Banner from './assets/banner.png';
import BannerSp from './assets/banner-sp.png';
import styles from './CashbackTopBanner.module.scss';

export default function CashbackTopBanner() {
  return (
    <section>
      <Hidden xsDown>
        <div className={styles.background}>
          <img src={Banner} alt="banner" />
        </div>
      </Hidden>
      <Hidden smUp>
        <img src={BannerSp} width="100%" height="auto" alt="banner-sp" />
      </Hidden>
    </section>
  );
}
