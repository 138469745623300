import React from 'react';
import { PAIDY_HOST } from 'src/constants';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import Condition202305 from 'src/components/Merchant/Campaign/GeneralCashback202305/Condition202305';
import styles from 'src/components/Merchant/Campaign/GeneralCashback202305/Condition202305.module.scss';

const CONDITIONS = [
  {
    title: 'キャンペーン概要',
    text: (
      <>
        キャンペーン期間中に決済画面でペイディの翌月あと払い、
        <OutboundLink
          href={`${PAIDY_HOST}/npay`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          手数料無料*の分割あと払い
        </OutboundLink>
        、
        <OutboundLink
          href={`${PAIDY_HOST}/apple`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          ペイディあと払いプランApple専用
        </OutboundLink>
        、もしくは
        <OutboundLink
          href={`${PAIDY_HOST}/paidycard`}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          ペイディカード
        </OutboundLink>
        を利用してお買い物すると、抽選で100名様に最大10万円キャッシュバックが当たる。
      </>
    ),
    note: (
      <>
        *口座振替・銀行振込のみ無料
        <br />
        <br />
        ※キャンペーン期間中に上記条件でお買い物された方に、4月30日までにキャッシュバックを実施。4月30日ごろにペイディアプリおよびMyPaidyに反映されます。決済画面には反映されませんのでご注意ください。
      </>
    ),
  },
  {
    title: 'キャンペーン期間',
    text: '2025年3月21日 (金) 6:00～4月19日 (土) 23:59',
  },
];

export default function Condition({ id }) {
  return <Condition202305 id={id} conditions={CONDITIONS} />;
}
