import { Container, Typography, Box } from '@material-ui/core';
import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { PAIDY_HOST } from 'src/constants';
import Benefit1 from 'src/images/campaign/tvcmlaunch_cashback_202404/benefit-1.png';
import styles from './PaidyBenefits.module.scss';

const DATA = [
  {
    src: Benefit1,
    name: '12pay_campaign',
    href: `${PAIDY_HOST}/npay`,
  },
  {
    src: require('./assets/benefit-ip-16.png'),
    name: 'apple ip 16',
    href:
      'https://www.apple.com/jp/shop/buy-iphone/iphone-16?cid=aos-jp-aff-bp-paidy-tvcm-event',
  },
];

export default function PaidyBenefits202501({ title }) {
  return (
    <section className={styles.section}>
      {title && <Typography className={styles.title}>{title}</Typography>}
      <Container className={styles.container}>
        {DATA.map((data) => (
          <Box>
            <OutboundLink
              href={data.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={styles.benefitImg}
                src={data.src}
                alt={data.name}
                width={311}
                height={420}
              />
            </OutboundLink>
            {data.note && (
              <Typography className={styles.note}>{data.note}</Typography>
            )}
          </Box>
        ))}
      </Container>
    </section>
  );
}
